const Style = {
  base: [
    "color: #333",
    "padding: 2px 4px",
    "border-radius: 6px"
  ],
  warning: [
    "color: #eee",
    "background-color: red"
  ],
  success: [
    "background-color: green"
  ],
  genug: [
    "color: #b2de5cff",
    "display: inline-block",
    "background-color: #333",
    "padding: 30px",
    "margin: 10px"
  ]
}

const ascii = "       -+***+-   :*******+  :*+     .*=  -*-     =*:    :+***+-.                          \n" +
    "  *@%=-:-+%-  =@#::::::  -@@%-   -@#  +@*     *@=  -@@+-::=#*                       \n" +
    " *@*          =@#:::::   -@%*@#. -@#  +@*     *@= :@%                               \n" +
    " #@=     :%*  =@%*****.  -@# :%@+-@#  +@*     *@= =@#      #%            .++        \n" +
    " :@@-    :@#  =@*        -@#   =@@@#  -@%.   .@@:  %@+     %@            #.@+     * \n" +
    "  .+%@%#%@%=  =@@######  -@#    .#@#   -#@%#%@#:    =#@%##@@* -@:     :#:% *@    += \n" +
    "      ...                                 ...          ...     :-     .@%% :@-  .#  \n" +
    "                                                       :**+    #@:    +#@@  *% .*   \n" +
    "                                               ::      @= @    #@#   -* =*   ++=    \n" +
    "                                             -%.=:    -@*=.   *:#@+ =+              \n" +
    "                                             %@:     :#%%   :+.  :==.               \n" +
    "                                             .+#%*=:+= .+*--:                       \n" +
    "                                                 :%@*                               \n" +
    "                                                 +=%@                               \n" +
    "                                                -* %+                               \n" +
    "                                                :*=-                                \n" +
    "                                                                                    \n" +
    "                                                                                    \n" +
    "      Es ist genug für alle da                                  \n" +
    "                                                                                       ";

const log = (text, extra = []) => {
  let style = Style.base.join(';') + ';';
  style += extra.join(';'); // Add any additional styles
  console.log(`%c${text}`, style);
}

// log(ascii, Style.genug);


const headers = document.querySelectorAll('.headertemplate');
const target = document.querySelector('.headerContent');
let index = 0;

const setHederContentFromTemplate = () => {
    $(target).fadeOut('slow', () => {
        target.innerHTML = headers[index].innerHTML;
        index++;
        if (index+1 > headers.length) {
            index = 0;
        }

        $(target).fadeIn('slow', () => {
            setTimeout(() => {
                setHederContentFromTemplate();
            }, 5000);
        });
    });


}

if(headers.length > 0) {
    setHederContentFromTemplate();
}
